<template></template>

<script>
export default {
   
    computed: {
        title() {
            return this.$store.state.info.title;

        }
    },
    watch: {
        title: {
            handler() {
                document.querySelector("title").innerHTML = this.title
            },
            immediate : true
        }
    }
}
</script>